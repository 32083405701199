<template>
  <div class="standard-page">
    <div class="d-flex justify-end mb-4">
      <v-btn
        depressed
        color="primary"
        :to="{ name: 'CampaignPlanningSearch' }"
      >
        <v-icon left>search</v-icon>
        Search Influencers
      </v-btn>
    </div>

    <!-- Data Table -->
    <template v-if="tableItems.length">
      <v-data-table
        :headers="tableHeaders"
        :items="tableItems"
        hide-default-footer
        disable-pagination
        class="elevation-1"
      >
        <template v-slot:item.action="{ item }">
          <v-btn
            small
            depressed
            class="mr-3"
            color="primary"
            @click="selectedItem = item; shouldShowDeleteDialog = true;"
          >
            Delete

            <v-icon right>
              delete
            </v-icon>
          </v-btn>

          <v-btn
            small
            depressed
            color="primary"
            @click="$router.push({ name: 'CampaignPlanningDetails', params: { campaignPlanId: item.id } })"
          >
            View

            <v-icon right>
              navigate_next
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </template>

    <!-- Fallback -->
    <template v-else-if="!isLoading">
      <div class="text-center">
        No campaign plans found.
      </div>
    </template>

    <!-- Confirm delete dialog -->
    <v-dialog
      v-model="shouldShowDeleteDialog"
      :persistent="isLoading"
      max-width="500"
    >
      <v-card v-if="selectedItem">
        <v-card-title>
          Confirm your action
        </v-card-title>

        <v-card-text>
          You are about to delete plan "{{ selectedItem.name }}". <br />
          This cannot be undone!
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            :disabled="isLoading"
            @click="shouldShowDeleteDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            text
            color="primary"
            :disabled="isLoading"
            :loading="isLoading"
            @click="handleDelete"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: "CampaignPlanningIndex",

  data: () => ({
    isLoading: false,

    selectedItem: null,
    shouldShowDeleteDialog: false,

    // related to table view
    tableHeaders: [
      {
        text: "Sr. No.",
        value: "index",
        sortable: false,
      },
      {
        text: "Name",
        value: "name",
        sortable: false,
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        align: 'end'
      }
    ],
  }),

  computed: {
    /**
     * Get the data from vuex state
     */
    tableItems() {
      return this.$store.getters["campaignPlanning/items"]
        .map((item, index) => ({
          ...item,
          index: index + 1
        }))
    }
  },

  methods: {
    /**
     * Make the network request to fetch the campaign plan list
     *
     * @returns {void}
     */
    async fetchItems() {
      this.isLoading = true
      await this.$store.dispatch("campaignPlanning/fetchItems")
      this.isLoading = false
    },

    /**
     * Make network request to delete each selected row
     *
     * @returns {void}
     */
    async handleDelete() {
      this.isLoading = true

      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)

      try {
        await axios({
          url: `/api/campaign-plans/${this.selectedItem.id}`,
          method: "DELETE"
        })

        // Sync the model items
        this.fetchItems()
      } catch (error) {
        logger({ type: "CampaignPlanningIndex Delete Error", error })

        this.$store.dispatch("toasts/add", { text: "An error occurred!" })
      } finally {
        // Once all requests have been made, remove the loader
        this.$store.dispatch("loaders/remove", loaderId)

        // Reset the local state
        this.isLoading = false
        this.selectedRows = {}
        this.shouldShowDeleteDialog = false
      }
    },
  },

  // When the component is ready
  mounted() {
    // Fetch the data from API
    this.fetchItems()
  }
}
</script>
